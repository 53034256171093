html, body {
    min-height: 650px;
}

body {
    overflow: hidden;

}

.fragmentIframe html, body {
    overflow: visible;
}

.fragmentIframe {
    height: 100%;
}


.Cursor {
    cursor: pointer;
}

.mui-card {
    border-radius: 16px !important;
    box-shadow: 0 4px 11px rgba(194, 209, 217, 0.46) !important;
}

.containerArrow {
    display: grid;


    align-items: center;
    justify-content: center;
}

.containerArrow .Arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-top: -5px;
}

.content-image {
    width: 350px;
    display: block;
    margin: auto;
    border: 2px solid #dfe3e7;
    border-radius: 8px;
}

@media (max-width: 600px) {
    .image-sign-up {
        height: 145px;
        position: absolute;
        bottom: 0;
    }
}

@media (max-width: 300px) {
    .image-sign-up {
        height: 140px;
    }
}

@media (max-width: 600px) {
    .image-verify {
        height: 160px;
    }
}

@media (max-width: 300px) {
    .image-verify {
        height: 120px;
    }
}

@media (max-width: 600px) {
    .titlesingup {
        padding: 0 !important;
        padding-top: 10px !important;
    }
}

/* TODO P */
.formulario > div {
    margin-bottom: 20px;
}

.formulario label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    margin-bottom: 5px;
    color: #404a56;
}

.Drawer {
    margin-left: 74px;
    padding: 3%;
}

.formulario .error {
    color: #b00020;
    font-size: 12px;
    margin-left: 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.head-login-container-credit {
    height: 180px;
    padding-top: 54px;
    background: linear-gradient(4.38deg,
    #fabe00 5.73%,
    #fabe00 52.18%,
    #fbcc34 93.05%);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

@media (max-width: 600px) {
    .head-login-container {
        height: 100px;
        background: linear-gradient(4.38deg,
        #fabe00 5.73%,
        #fabe00 52.18%,
        #fbcc34 93.05%);
        border-radius: 0 0 30px 30px;
    }
}

@media (min-width: 600px) {
    .head-login-container, .head-login-container-v2 {
        height: 200px;
        background: linear-gradient(4.38deg,
        #fabe00 5.73%,
        #fabe00 52.18%,
        #fbcc34 93.05%);
        border-radius: 0 0 30px 30px;
        justify-content: center;
    }
}

.head-login-container-v2 img {
    width: 224px;
}

@media (min-width: 600px) {
    .head-credit-detail {
        height: 90px;
        padding-top: 100px;
        background: linear-gradient(180deg, rgba(255, 216, 88, 1) 22%, rgba(250, 190, 0, 1) 100%);
    }
}


@media (max-width: 600px) {
    .head-login-container-v2 {
        height: 256px;
        padding-top: 54px;
        background: linear-gradient(4.38deg,
        #fabe00 5.73%,
        #fabe00 52.18%,
        #fbcc34 93.05%);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .head-credit-detail {
        height: 340px;
        padding-top: 10px;
        background: linear-gradient(180deg, rgba(255, 216, 88, 1) 22%, rgba(250, 190, 0, 1) 100%);
    }

    .head-login-container-credit {
        height: 180px;
        padding-top: 54px;
        background: linear-gradient(4.38deg,
        #fabe00 5.73%,
        #fabe00 52.18%,
        #fbcc34 93.05%);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}

.gridimage {
    margin-top: 77px !important
}

.MuiCircularProgress-svg {
    color: #fabe00;
}

.head-login-container img {
    width: 224px;
}

.body-login-container, .body-login-container-v2 {
    position: absolute;
    z-index: 0;
    margin-top: 0px !important;
    box-shadow: 2px 4px 4px rgba(185, 185, 185, 0.25);
    background: #ffffff;
    padding: 0px 50px;
    width: 950px;
    height: 850px;
    top: 147px;
    border-radius: 21px;
}

@media (max-width: 600px) {
    .body-card-notification {
        height: 571px;
        top: 57px;
        min-width: 100% !important;
        align-items: center !important;
        margin-top: 0px !important;
    }
}

@media (min-width: 600px) {
    .body-card-notification{
        box-shadow: 2px 4px 4px 0px rgba(185, 185, 185, 0.25) !important;
        width: 598px !important;
    }
    .loading{
        margin-top: 320px !important;
        height: 367px !important;
    }
    .error{
        height: 424px !important;
        margin-top: 350px !important;
    }
}

.body-card-notification {
    position: absolute;
    z-index: 0;
    background: #ffffff;
    padding: 0px 36px;
    border-radius: 21px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

@media (max-width: 600px) {
    .body-card-notification-no-padding {
        height: 100%;
        top: 57px;
        min-width: 100% !important;
        align-items: center !important;
        padding: 0px !important;
    }
}

.body-card-notification-no-padding {
    position: absolute;
    z-index: 0;
    margin-top: 0px !important;
    background: #ffffff;
    padding: 0px 36px;
    border-radius: 21px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

@media (max-width: 600px) {
    .body-login-container-v2 {
        height: 571px;
        top: 130px;
    }
}

.body-full-container {
    position: absolute;
    z-index: 0;
    box-shadow: 2px 4px 4px rgba(185, 185, 185, 0.25);
    background: #ffffff;
    padding: 0px 36px;
    width: 950px;
    height: 850px;
    top: 147px;
    border-radius: 21px;
}

@media (max-width: 600px) {
    .body-full-container {
        height: 571px;
    }
}

.body-login-container-v2 .footer {
    margin-top: 30px;
    margin-bottom: 40px;
}

.body-login-container-v2 .text {
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4c5866;
}


/* TODO VERIFICA ESTO */
.body-login-container .footer {
    margin-top: 30px;
    margin-bottom: 40px;
}

.body-login-container .text {
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4c5866;
}

@media (max-width: 600px) {
    .footer-login-container {
        width: 98%;
        height: 72px;
        background: #f8f9fb;
        position: relative;
        bottom: 0px;
        left: 0px;
        display: none !important;
    }
}

@media (min-width: 600px) {
    .footer-login-container {
        width: 98%;
        height: 72px;
        background: #f8f9fb;
        position: fixed;
        bottom: 0px;
        left: 0px;
    }
}

.footer-login-container .text {
    font-family: Roboto, sans-serif;
    font-style: light;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4c5866;
}

.formulario {
    padding: 50px 85px;
}

@media (max-width: 600px) {
    .formulario {
        padding: 0
    }

    .text-link {
        color: #363853;
        font-family: Readex Pro, serif;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 12px;
        line-height: 14px;
        padding-top: 35px;
        text-decoration-color: #FABF00;
        text-align: end;
    }
}

@media (min-width: 502px) and (max-width: 600px) {
    .grid-form-signup-verify {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}

.formulario .title {
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #404a56;
    padding: 50px 0;
    padding-bottom: 10px;
    line-height: 28px;
}

.formulario .subTitle {
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #4C5866;
    padding: 30px;
    padding-bottom: 70px;
    line-height: 28px;
}

@media (max-width: 600px) {
    .formulario .title {
        font-size: 20px;
        line-height: 22px;
    }
}

@media (max-width: 600px) {
    .formulario .subTitle {
        padding: 0;
        padding-top: 15px;
        padding-bottom: 40px;
        text-align: center;
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .formulario .title {
        font-size: 20px;
        line-height: 22px;
        padding-top: 15%;
    }
}

@media (max-width: 400px) {
    .formulario .subTitle {
        padding: 0;
        padding-top: 5%;
        padding-bottom: 15%;
        text-align: center;
        font-size: 16px;
    }
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.swal-button-contained {
    width: 118px;
    height: 40px;
    background: #363853 !important;
    color: #343c46 !important;
    border-radius: 4px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.swal-button-outlined {
    width: 118px;
    height: 40px;
    background: #ffffff !important;
    color: #343c46 !important;
    border: 1px solid #363853 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.swal-title {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #404a56 !important;
    text-align: start !important;
    margin: 0 0.4em !important;
}

.swal-htmlContainer {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #404a56 !important;
    width: 532px !important;
    height: 367px !important;
    margin: 0px !important;
}

.swal-container {
    max-width: 532px !important;
    max-height: 367px;
    width: 100% !important;
    height: 100%;
}

.link-none {
    text-decoration: none !important;
}

.mui-verticalTab {
    border-bottom: 1;
    border-color: "divider";
}

.iFrame {
    border: none !important;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 16px 24px rgba(52, 60, 70, 0.14),
    0px 6px 30px rgba(52, 60, 70, 0.12), 0px 8px 10px rgba(52, 60, 70, 0.2);
    border-radius: 8px;
}

.modal-box .icon-close-modal {
    position: absolute;
    top: 13.75px;
    right: 22px;
    font-size: 20px;
    cursor: pointer;
}

.modal-box .input-copy {
    border: 1px solid #ccc;
    width: 80%;
    border-radius: 8px 0 0 8px;
    padding: 1px 4px;
    color: #004fb6;
}

.modal-box .button-copy {
    border: 1px solid #bfc7d0;
    padding: 10px;
    width: 15%;
    background: #004fb6;
    color: #fff;
    border-radius: 0 8px 8px 0;
}

.action-button {
    font-size: 16px !important;
}

@media (min-width: 600px) {
    .action-button {
        display: flex;
        padding: 16px 28px !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 16px !important;
        flex: 1 0 0 !important;
        border-radius: 16px !important;
        width: 223px;
        height: 48px !important;
    }
}

.grid-button-sign-up-pass {
    margin-top: 40px !important;
}

.action-button-outlined {
    font-size: 16px !important;
    background-color: #FFFFFF !important;
    border: 2px solid #363853 !important;
    color: #363853 !important;
}

@media (min-width: 601px) {
    .action-button-outlined {
        height: 48px !important;
    }
}

.pageHeaderButton {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-right: 8px !important;
    padding-left: 0px !important;
}

.action-button-pay {
    font-size: 16px !important;
    background-color: #FFFFFF !important;
    border: 2px solid #363853 !important;
    color: #363853 !important;
}

@media (min-width: 601px) {
    .action-button-pay {
        width: 120px !important;
        height: 44px !important;
        border-radius: 16px !important;
    }
}

.action-button:hover {
    background-color: #363853 !important;
}

.action-button:active {
    background-color: #363853 !important;
}

.action-button-outlined:hover {
    background-color: #e7e7e7 !important;
    border: 1px solid #363853 !important;
}

.action-button-outlined:active {
    background-color: #e7e7e7 !important;
    border: 1px solid #363853 !important;
}

.swal-button-outlined:hover {
    background-color: #e7e7e7 !important;
    border: 1px solid #363853 !important;
}

.swal-button-outlined:active {
    background-color: #e7e7e7 !important;
    border: 1px solid #363853 !important;
}

.swal-button-contained:hover {
    background-color: #363853 !important;
}

.swal-button-contained:active {
    background-color: #363853 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2218%22%20height%3D%2220%22%20viewBox%3D%220%200%2018%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M14%2011H9V16H14V11ZM13%200V2H5V0H3V2H2C0.89%202%200.00999999%202.9%200.00999999%204L0%2018C0%2019.1%200.89%2020%202%2020H16C17.1%2020%2018%2019.1%2018%2018V4C18%202.9%2017.1%202%2016%202H15V0H13ZM16%2018H2V7H16V18Z%22%20fill%3D%22%23404A56%22%2F%3E%0A%3C%2Fsvg%3E");
    height: 20px;
    width: 20px;
    padding-top: 0;
}

.action-button-secondary:hover {
    background-color: #363853 !important;
}

.action-button-secondary:active {
    background-color: #363853 !important;
}

.action-button-outlined-secondary:hover {
    background-color: #e7e7e7 !important;
    border: 1px solid #363853 !important;
}

.action-button-outlined-secondary:active {
    background-color: #e7e7e7 !important;
    border: 1px solid #363853 !important;
}

.swal2-actions {
    display: block;
    text-align: center !important;
    width: 100% !important;
}

.swal-actions {
    justify-content: center !important;
    width: 490px !important;
    margin-top: 42px !important;
    margin-bottom: 42px !important;
}

.swal-htmlContainer-with-swal-actions {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #404a56 !important;
    width: 500px !important;
    padding-left: 36px !important;
    height: 267px !important;
    margin: 0px !important;
}

.swal2-modal {
    height: 362px !important;
}

.no-information-message {
    text-align: center;
}

/* PRIVACY */
.head-privacy-container {
    width: 100%;
}

.img-tigo-privacy {
    width: 12%;
    position: absolute;
    z-index: 1;
    top: 15px;
}

.privacy-card {
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    width: 80%;
    height: 60vh;
    top: 10px;
    background: #fff;
    box-shadow: 0px 10px 38px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 80px;
}

.footer-privacy-container {
    width: 98%;
    height: 72px;
    background: #00377d;
    position: fixed;
    bottom: 0px;
    left: 0px;
}

@media screen and (max-width: 1500px) {
    .privacy-card {
        height: 46vh;
    }

    .img-tigo-privacy {
        width: 10%;
    }
}

@media screen and (max-width: 1024px) {
    .privacy-card {
        height: 40vh;
    }

    .img-tigo-privacy {
        width: 20%;
    }
}

@media screen and (max-width: 912px) {
    .privacy-card {
        width: 75%;
        height: 75vh;
        padding: 50px;
    }
}

@media screen and (max-width: 820px) {
    .img-tigo-privacy {
        width: 30%;
    }
}


@media screen and (min-width: 600px) {
    .grid-checkbox {
        max-width: 40px !important
    }
}

.privacy-card {
    width: 75%;
    height: 70vh;
    padding: 50px;
}

.img-tigo-privacy {
    width: 30%;
}

.onlydesktop {
    display: none;
}

.contractTitle {
    font-size: 4vw !important;
    color: #00377D !important;
}

.contractTitle {
    font-size: 3vw !important;
}

.contractText,
.footer-login-container .text {
    font-size: 14px;
}

.footer-login-container {
    padding: 0 20px;
}

.contractTermsNoticePrivacy {
    font-size: 15px !important;
    color: #6C8297 !important;
}

.contractTermsNoticePrivacyLink {
    color: #404A56 !important;
    font-size: 15px !important;
}

.contractTermsNoticePrivacySubtitle {
    color: #404A56 !important;
    font-size: 18px !important;
}


@media screen and (max-width: 540px) {
    .privacy-card {
        width: 75%;
        height: 55vh;
        padding: 50px;
    }

    .img-tigo-privacy {
        width: 40%;
    }

    .contractTitle {
        font-size: 20px !important;
    }

    .contractSubtitle {
        font-size: 14px !important;
    }

    .contractTermsNoticePrivacy {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 414px) {
    .privacy-card {
        width: 70%;
        height: 65vh;
        padding: 50px;
    }

    .img-tigo-privacy {
        width: 50%;
    }
}

@media screen and (max-width: 412px) {
    .img-tigo-privacy {
        width: 50%;
    }
}

@media screen and (max-width: 375px) {
    .privacy-card {
        width: 90%;
        padding: 5px;
        height: 70vh;
    }
}

.body-card-container {
    height: 78vh !important;
    width: 100% !important;
}

.body-card-container .text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4c5866;
}

.text-link {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #363853;
    text-align: end;
}

.detail-Grid {
    margin: 10px;
    border-radius: 20px;
    width: 100px;
}

@media screen and (max-width: 375px) {
    .detail-Grid {
        width: 100%;
    }
}

.div-wallet .MuiTooltip-tooltip {
    margin-top: -17px !important;
}